class ApiGenerator {
		// Call API with array of url
		static async sendRequest(url, options){
			options.setLoaderData({
				...options.generateLoaderDataCount,
				currentUrl: url,
				message: 'Generating ads for:',
			})

			const urls = [url]
			const body = { urls }
			const response = await fetch(
				"https://ads-genreator-server.aws.alya.ai/generateHeadlines",
				{
					method: "post",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(body),
				});
			const result = await response.json();

			if(result.error) {
				console.error(result.contentError)
				return result
			}
			return this.filtreErrorData(result)
		}

		//Filter Error in Headline and Description
		static filtreErrorData(data){
				let dataClean = []
				for(const site in data){
					let cleanDataSite = {}
					let itemDataClean = {}

					itemDataClean = ApiGenerator.cleanArrayData(data[site].headline, 'headline')
					itemDataClean = ApiGenerator.replaceSpecifiqueChar(itemDataClean)
					cleanDataSite['headline'] = itemDataClean

				  itemDataClean = ''

					itemDataClean = ApiGenerator.cleanArrayData(data[site].description, 'description')
					itemDataClean = ApiGenerator.replaceSpecifiqueChar(itemDataClean)
					cleanDataSite['description'] = itemDataClean

					cleanDataSite['url'] = data[site].url
					dataClean.push(cleanDataSite)
				}
				return dataClean
		}
		static cleanArrayData(arrayData, category){
				let array = arrayData.map( el => { 
						if(/###/.test(el)) return ''
						if(category === 'headline' && (el.length > 500)) return ''
						if(category === 'description' && (el.length > 2000)) return ''
						return el
				})
				//remove the cell empty, null, ''
				array = array.filter(Boolean)
				return array
		}
		static replaceSpecifiqueChar(arrayData){
				let arrayDataReplaced = []
				arrayData.forEach( data => {
						let textReplace = data.replace(';', ',').replace('\\r\\n', ' ')
						arrayDataReplaced.push(textReplace.trim())
				})
				return arrayDataReplaced
		}

	//Test is url is valid
		static async isValidUrl(url, options){
			const isFormatValid = ApiGenerator.isValidFormatUrl(url)
			if(!isFormatValid) {
				options.addNotify({type: 'warn', content: 'Url format invalid'})
				options.addTagError('warn')
				return false
			}

			return true
		}
	static async isStatusCode200(url, options){
			options.setLoaderData({
				...options.generateLoaderDataCount, 
				currentUrl: url,
				message: 'Check status code:',
			})

			const isOkSite = await ApiGenerator.isWebSiteOk(url)
			if(!isOkSite.ok) {
				options.addNotify({type: 'error', content: 'Error Status : ' + isOkSite.why})
				options.addTagError('error')
				return false
			}
		return true
	}
		static isValidFormatUrl(url){
			const isFormatValid = 
				new RegExp('(https?)://(www.)?[a-z0-9]+.[a-z0-9]+(/)?.*')

			if(!isFormatValid.test(url)) return false
			
			const urlT = url.split('.')
			//Test specif wrong format url : http://example
			if( !urlT[0].includes('www') && urlT.length < 2) return false
			//Test specif wrong format url : http://www.example
			if(urlT[0].includes('www') && urlT.length < 3) return false

			// Test specif wrong format url : chttps://www.example.com
			const regexBeforeHttp = new RegExp('[0-9a-z](https?)://')
			if(regexBeforeHttp.test(url)) return false

			return true
		}
		static async isWebSiteOk(url){
			const cloudfonction = "https://us-central1-alya-gcp.cloudfunctions.net/Alya_UTM_checkURL_test"
			const testUrl = cloudfonction + '?url=' + url

			let response
			try {
				response = await fetch(testUrl, {
					method: 'GET',
				})	
				response = await response.json();

				if(response[0].status !== 200) return { ok: false, why: response[0].status }
			} catch(e) {
				return { ok: false, why: response[0].status }
			}
			return { ok: true }
		}
}

export default ApiGenerator
