import React, { useState, useRef } from "react";
import icon_Export from "../img/download.svg";
import ExportCSV from "../model/ExportCSV";

function ExportPopup({ data, sites, close }) {
	const [formData, setFormData] = useState({ radio: "", checkbox: {} });
	const [activatedCheck, setActivatedCheck] = useState(false);
  const refExportCSV = useRef(new ExportCSV(
			data, 
			"Final URL", 
			["headline","description",]
	));

	const handleChange = (e) => {
		let newData = {};

		if (e.target.type == "radio") {
			newData["radio"] = e.target.value;
			changeActivatedCheckox(e.target.value);
		} else if (e.target.type == "checkbox") {
			newData["checkbox"] = { ...formData.checkbox };
			newData["checkbox"][e.target.value] = e.target.checked;
		}
		setFormData((old) => ({ ...old, ...newData }));
	};

	const changeActivatedCheckox = (value) => {
		if (value == "all") setActivatedCheck(false);
		else setActivatedCheck(true);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (formData.radio == "all") refExportCSV.current.linkDatasToExport(data);
		else if (formData.radio == "select") refExportCSV.current.linkDataToExport(data, formData);
	};

	return (
		<div className="exportPopup">
			<div className="head">
				<div onClick={close} className="button-close">
					x
				</div>
				<div className="icon_Export">
					<img src={icon_Export} alt="icon exportation" />
				</div>
				<div className="containerTitle">
					<div className="title">Export Ad report</div>
					<div className="subTitle">Select export</div>
				</div>
			</div>
			<form id="formExport" action="" onSubmit={handleSubmit}>
				<div className="containerExportSelect">
					<div className="exportAll">
						<input
							type="radio"
							id="all"
							name="export"
							value="all"
							onChange={handleChange}
						/>
						<label htmlFor="all">Export all (1 file)</label>
					</div>
					<div className="exportSelected">
						<input
							type="radio"
							id="select"
							name="export"
							value="select"
							onChange={handleChange}
						/>
						<label htmlFor="select">Select (1 file by url)</label>
					</div>
					{sites.map((site, i) => (
						<div key={"ch-" + i} className="containerInput">
							<input
								id={site}
								value={site}
								type="checkbox"
								onChange={handleChange}
								disabled={!activatedCheck}
							/>
							<label
								htmlFor={site}
								className="hovertext"
								data-hover={site}
							>
								{refExportCSV.current.splitUrl(site, "interface")}
							</label>
						</div>
					))}
				</div>
				<div className="container-button-export">
					<input
						className="popup-button-export"
						type="submit"
						name="submit"
						value={"Export"}
					/>
				</div>
			</form>
		</div>
	);
}

export default ExportPopup;
