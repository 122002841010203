import './App.css';

import React, { useState, useEffect } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import InputGenerator from './components/InputGenerator'
import Loader from './components/Loader'
import TableData from './components/TableData'
import Notify from './components/Notify'

//import fakeDatas from './devData/fakeDatas.json'

function App() {
	
	const [step, setStep] = useState(1)
	const [urls, setUrls]	= useState({ 0: ''})

	//State for InputGenerator
	const [classInputs, setClassInputs] = useState({})
	const [responseAPI, setResponseAPI] = useState()
	const [loaderData, setLoaderData] = 
		useState({ message: '', countTotal: 0, currentCount: 0, currentUrl: ''})

	//State sharing for Notify
	const [notifyList, setNotifyList] = useState([])
	const addNotify = ({type, content}) => {
		setNotifyList( old => ([...old, {type, content, id: old.length}]) )
	}

  return (
    <div className="App main">
			<Notify list={notifyList} setList={setNotifyList}/>
			<Header />
			<div className="alya-gtp3">          
			{ step == 1 ? 
					<InputGenerator 
						urls={{ list: urls, setUrls}} 
						addNotify={addNotify}
						setStep={setStep}
						setLoaderData={setLoaderData}
						setResponseAPI={setResponseAPI}
						tagClassInputs={[classInputs, setClassInputs]}
					/>
				: step ==2 ?
					<Loader data={loaderData}/>
				: <TableData data={responseAPI} backward={() => setStep(1)} addNotify={addNotify}/>
			}
			</div>
			<Footer />
    </div>
  );
}

export default App;
