import React, {useState } from 'react'
import ApiGenerator from '../model/ApiGenerator'

function InputGenerator({
	urls, 
	setStep, 
	addNotify, 
	setLoaderData, 
	setResponseAPI,
	tagClassInputs
}){

	const [classInputs, setClassInputs] = tagClassInputs
	const urlsInputGenerate = Object.values(urls.list)
	urlsInputGenerate.shift()

	const handleAdd = () => {
		const updateValue = { [urlsInputGenerate.length + 1]: '' }
		urls.setUrls( old => ({
				...old,
				...updateValue
			})
		)
	}
	const handleRemove = () => {
		const properties = Object.keys(urls.list)
		if(properties.length == 1) return 0

		const lastProperty = properties[properties.length - 1]

		urls.setUrls( old => {

			delete old[lastProperty]
			return { ...old }
		})
	}

	const actionsByUrls = async (urlsTab, fnCheck, step) => {
		const tabValidUrls = []
		const length = urlsTab.length
		const additionalStep = (step - 1) * length
		/* countTotal equal the number: 
		 * - url test
		 * - generate results by url
		 */
		const generateLoaderDataCount = {countTotal: length*2, currentCount: 1}

		for(let i = 0; i < length; i++){
				const addTagError = (tag) => setClassInputs(old => ({...old, [i]: tag}))
				generateLoaderDataCount.currentCount = (i + 1) + additionalStep
				tabValidUrls.push(await ApiGenerator[fnCheck](
						urlsTab[i], 
				 		{addNotify, addTagError, setLoaderData, generateLoaderDataCount}
				))
		}
		return tabValidUrls
	}
	const handleSubmit = async (e) => {
		e.preventDefault()	
		let urlsTab = [] 
		for(const key in urls.list) urlsTab.push(urls.list[key])

		//Test format of urls before any fetch
		const urlsValid = await actionsByUrls(urlsTab, 'isValidUrl', 1)
		if(!urlsValid.includes(false)){
				//Activate loader
				setStep(2)

				const checkUrls = await actionsByUrls(urlsTab, 'isStatusCode200', 1)
				if(checkUrls.includes(false)){ 
					setStep(1)
					return 0
				}

				const resultApis = await actionsByUrls(urlsTab, 'sendRequest', 2)
				resultApis.forEach( result => {
					if(result.error){ 
						addNotify({ type: 'error', content: result.message})
						setStep(1)
						return 0
					}
				})
	
				const resultGenerateApi = resultApis.map(el => el[0])
				console.log(resultGenerateApi)

				if(resultGenerateApi[0] === undefined) {
					addNotify({ type: 'error', content: 'Error: No data to generate'})
					addNotify({ type: 'warn', content: 'Please check your urls and restart'})
					setStep(1)
					return 0
				}

				setResponseAPI(resultGenerateApi)
				//Display TableData
				setStep(3)
		}
	}
	
	return <form id="headlines" action="" onSubmit={handleSubmit}>
					<div className='input-section'>
            <label className="form-label" htmlFor="url">URL Landing Page</label>
						<Input required={1} 
							value={urls.list[0] ??=''} 
							id={0} setUrls={urls.setUrls}
							addClass={classInputs[0]}		
							setClass={setClassInputs}
						/>
						<div className='controller-number-input'>
						<div 
							className='addInput hovertext' 
							onClick={handleAdd}
							data-hover='Add more'
						>+</div>
						<div 
							className='removeInput hovertext' 
							onClick={handleRemove}
							data-hover='Remove'
						>-</div>
					</div>
					</div>
					<ul className='input-section'>

						{ urlsInputGenerate.map( (url, i) => <li key={i}>
								{ 
									<Input 
										required={0} 
										value={url} 
										setUrls={urls.setUrls} 
										id={i + 1} 
										addClass={classInputs[i+1]}
										setClass={setClassInputs}
									/> 
								}

							</li>) }

					</ul>
						<div className='contener-button'>
            	<input 
             	 className="generate-button"
								type="submit" 
								id="submit" 
								name="submit"
								value="Generate"
							/>
						</div>
          </form>
}

function Input({value, required, setUrls, id, addClass, setClass}) {
	const handleChange = (e) => {
		
		setClass(old => ({...old, [id]: '' }))
		const updateValue = { [id]: e.target.value }
		setUrls( old => ({
				...old,
				...updateValue
			})
		)
	}

	return <input 
        className={"form-url-input " + addClass}
        type="text" 
        name="url" 
        placeholder="http(s)://..."
        required={required}
				defaultValue={value}
				onChange={handleChange}	
        />
}

export default InputGenerator
