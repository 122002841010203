import JSZip from "jszip";

class ExportCSV {
		/*
		 * @param {obj} data
		 * @param {string} columnName
		 * @param {Array} categories - [string]
		 */
		constructor(data, columnName, categories){
			this.columnName = columnName
			this.categories = categories
			this.data = data
		}

		generate(sites){
				const endLine = '\r\n'
				const limit = this.findLimit(this.data, sites)
				const row1 = this.generateColumn(sites[0], limit) + endLine

				let dataRow = ''
				sites.map( site => {
					dataRow = dataRow + this.generateRowData(site, limit) + endLine
				})

				return row1 + dataRow
		}

		findLimit(dataRaw, sites){
			const data = sites.map( site => dataRaw[site] ) 
			let limit = {limitH: 0, limitD: 0}
						
			let limitSites = data.map( site => {
				const limitH = site.headline.length
				const limitD = site.description.length
				return { limitH, limitD }
			})

			limit = limitSites.reduce( (prev, curr) => {
				const limitH = prev.limitH > curr.limitH ? prev.limitH : curr.limitH
				const limitD = prev.limitD > curr.limitD ? prev.limitD : curr.limitD
				return { limitH, limitD }
			}, limit)

			return { headline: limit.limitH, description: limit.limitD }
		}

		generateColumn(site, limit){
				let row = `${this.columnName};`

				this.categories.forEach(category => {
					const name = category.charAt(0).toUpperCase() + category.slice(1)
					row = row + this.generateRow(
							site,
							name, 
							'status', 
							limit[category]
					)}
				)
			return row
		}
		generateRowData(site, limit) {
				let dataRow = `${this.data[site].url};`

				this.categories.forEach(category => 
					dataRow = dataRow + this.generateRow(
							site,
							category, 
							'data', 
							limit[category]
					)
				)
				return dataRow
		}

				generateRow(site, category, rowStatus, limit){
						let row = '';

						for(let i = 0; i < limit; i++){
								if(rowStatus == 'status') {
									row = row + `${category} ${i + 1};`
								}
								else if(rowStatus == 'data') {
									if(!this.data[site][category][i]) row = row + ';'
									else row = row + `${this.data[site][category][i]};`
								}
						}
						return row
				}

	// data about all urls => 1 file
	linkDatasToExport(data){
		const sites = Object.keys(data);
		const csvData = this.generate(sites);

		const date = new Date().toISOString().split(".");
		const fileName = `adsgenerator_${date[0]}.csv`;

		if (window.showSaveFilePicker) this.saveAsDownload(csvData, fileName);
		else this.directDowload(csvData, fileName, "text/csv");
	}
		linkDataOneFileToExport(data, site){
				let index = null;
				data.forEach( (el, i) => {
					if(el.url == site) index = i;
				})

				const csvData = this.generate([index]);
				const date = new Date().toISOString().split(".");
				const currentUrl = this.splitUrl(site, "nameFile");
				const fileName = `adsgenerator_${currentUrl}_${date[0]}.csv`;

				if (window.showSaveFilePicker) this.saveAsDownload(csvData, fileName);
				else this.directDowload(csvData, fileName, "text/csv");
		}
	// data about all urls => 1 file by url
	async linkDataToExport(data, formData){
		let urls = [];
		let files = [];
		let countUrl = {};
		const date = new Date().toISOString().split(".");

		for (const url in formData.checkbox) urls.push(url);
		if(urls.length == 1){
				this.linkDataOneFileToExport(data, urls[0])
				return 0
		}

		urls.sort()
		urls.forEach((url, index) => {
			if (formData.checkbox[url] == true) {
				const site = data.findIndex((el) => el.url == url);
				let dataCsv = this.generate([site]);
				let oldUrl = index - 1 > 0 ? 
							this.splitUrl(this.splitUrl(urls.at(index-1), 'nameFile')) 
							: '';
				let currentUrl = this.splitUrl(this.splitUrl(url, 'nameFile'));

				if(oldUrl == currentUrl) countUrl[currentUrl] ? 
							countUrl[currentUrl]++ 
							: countUrl[currentUrl] = 1;

				const fileName = `adsgenerator_${currentUrl}_${countUrl[currentUrl] 
						? countUrl[currentUrl] + '_' 
						: ''}${date[0]}.csv`;
				files.push({ data: dataCsv, fileName });
			}
		});

		const zipRawData = await this.zipCsv(files);
		this.directDowload(
			zipRawData,
			`ads_generator_${date[0]}.zip`,
			"application/octet-stream"
		);
	}

	splitUrl(url, role){
		let newUrl = url.replace(/(http(s)?:\/\/)?(www\.)?/, "");

		if(role == "nameFile") {
			newUrl = newUrl.split("/");
			return newUrl[0];
		}

		let indexSlash = newUrl.indexOf("/");
		if(!newUrl.at(indexSlash + 1)) newUrl = newUrl.replace("/", "");

		if(role == "interface" && newUrl.length > 35) newUrl = this.shortUrl(newUrl);
		return newUrl;
	}
	shortUrl(url){
		let firstPartUrl = url.substring(0, 20);
		let secondPartUrl = url.slice(-15, -1);
		return firstPartUrl + '...' + secondPartUrl;
	}
	async zipCsv(files){
		let rawData;
		const zip = new JSZip();
		files.forEach((file) => zip.file(file.fileName, file.data));

		if (JSZip.support.uint8array) {
			rawData = await zip.generateAsync({ type: "uint8array" });
		} else {
			rawData = await zip.generateAsync({ type: "string" });
		}
		return rawData;
	}
	directDowload(csvData, fileName, type){
		const CSVFile = new Blob([csvData], { type: type });
		const hiddenLink = document.createElement("a");
		const url = window.URL.createObjectURL(CSVFile);

		hiddenLink.download = fileName;
		hiddenLink.href = url;
		hiddenLink.target = "_blank";

		hiddenLink.click();
	};
	/*
	 * File System Access API
	 * Feature is available only in secure contexts (HTTPS)
	 */
	async saveAsDownload(csvData, fileName){
		const opts = {
			suggestedName: fileName,
			types: [
				{
					accept: { "text/csv": [".csv"] },
				},
			],
		};

		const fileHandle = await window.showSaveFilePicker(opts);
		const writable = await fileHandle.createWritable();
		await writable.write(csvData);
		await writable.close();
	}
}

export default ExportCSV
