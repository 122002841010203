import React from 'react'
import icon_alya from '../img/alyaicon.png'

function Header(){
	return <div className='Header'>
        <a href="https://app.alya.ai/" target="blank">
          <img src={icon_alya} alt='icon alya' className="alya-icon" height="50px" width="50px"/>
        </a>
        <div className="alya-gtp3">          
          <h1 className="sub-headline"> 
						<a href='https://www.alya.ai/'>ALYA.ai</a>
					</h1>
          <div className="main-text">
            <h1 className="text-header">
							Search Ads Copy Generator
            </h1>
              <div className="text-list">
                <ol>
                  <li>
                    <b>Enter your URLs below</b>
                  </li>
                  <li>
                    <b>Choose your favorite headlines and descriptions</b>
                  </li>
                  <li>
                    <b>Export to CSV</b>
                  </li>
                </ol>
              </div>
            <div className="text-body">
              <p>
                <b>
                  Built by AI marketing specialists
                </b>
                - this will help you to get content ideas at a glance and enable
                you to create a smart copy.
              </p>
            </div>
          </div>
				</div>
			</div>
}

export default Header
