import React from 'react'

/*
 * {@param} data<object> : {message<string>, countTotal<number>, currentCount<number>, currentUrl<string>}
 */
function Loader({data}){
	return <div className='contener-loader'>
			<div className='loader'></div>
			<div className='loader-text'>
				<div className='data-message-loader'>{data.message}</div>
				<div className='data-url-loader'>{data.currentUrl}</div>
				<div className='data-count-loader'>[{data.currentCount} / {data.countTotal}]</div>
		</div>
		</div>
}

export default Loader
