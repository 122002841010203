import React from 'react'

function Footer(){
	return <footer className="footer">
				<div>Copyright ALYA 2022
					<div className='diviser'>|</div> 
						<a href="https://app.alya.ai/" target="blank">Homepage</a> 
						<div className='diviser'>|</div>
						<a href="https://docs.alya.ai/alya-docs/" target="blank">Help Center</a>
						<div className='diviser'>|</div>
						<a href="https://docs.alya.ai/contact/" target="blank">Contact Us</a>
						<div className='diviser'>|</div>
						<a href="https://docs.alya.ai/terms-of-use/" target="blank">Terms Of Use</a>
				</div>
       </footer>
}

export default Footer
