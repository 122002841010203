import React, {useState, useEffect} from 'react'
import ExportPopup from './ExportPopup'

import icon_export from '../img/download.svg'
import icon_edit from '../img/edit.png'
import icon_save from '../img/save.png'

/*
 * {@param} data: Array[ {headline: Array, description: Array, url: String} ]
 * {@param} backward: Function to go back to step 1
 * {@param} addNotify: Function to add notify
 */
function TableData({data, backward, addNotify}){
	const sitesUrls = data.map(el => el.url)
	const sites = Object.keys(data)

	let seeMoreDefaultData = {}
	data.forEach( el => seeMoreDefaultData[el.url] = 0)
	const edit = data.map( (el, i) => ({
		headline: el.headline.map( el => 0),
		description: el.description.map( el => 0),
		url: el.url
	}))

	const [popup, setPopup] = useState(0)
	const [seeMore, SetSeeMore] = useState(seeMoreDefaultData)
	const [dataTab, setDataTab] = useState(data)
	const [editData, setEditData] = useState(edit)

		const handleClickSeeMore = (site, tabFull) => {
			let newValue = {}
			newValue[site] = tabFull
			SetSeeMore(old => {
				return {...old, ...newValue}	
			})
		}
		const handleClick= (e) => {
			const content = e.target.innerHTML
			navigator.clipboard.writeText(content)
			addNotify({type: 'valid', content: 'Copied'})
		}
		const handleEdit = ({site, category, index}) => {
			setEditData(old => {
				let newData = [...old]
				newData[site][category][index] = 1
				return newData
			})
		}
			const handleSave = ({site, category, index}) => {
			setEditData(old => {
				let newData = [...old]
				newData[site][category][index] = 0
				return newData
			})
			addNotify({type: 'valid', content: 'Saved'})
			}
			const handleKeyDown = e => {
				if(e.key === 'Enter') e.preventDefault()
			}
			const handleChange = (e, {site, category, index}) => {
				setDataTab( old => {
					let newData = [...old]
					newData[site][category][index] = e.target.value
					return newData
				})
			}

		const generateRow = (site, category, limit) => {
				let list = []
				for(let i = 0; i < limit; i++){
					let name = category + ' ' + (i + 1)
					let response = data[site][category][i]
					const position = {site, category, index: i}

					list.push(
							<>
								<th>{name}</th>
								<td>

									{ !editData[site][category][i] ?

									<>
									<div onClick={handleClick}>
										{response}
									</div>
									<img onClick={() => handleEdit(position)} 
									src={icon_edit} />
									</>
									:
									<>
									<textarea className='edit'
										onChange={(e) => handleChange(e, position)}
										onKeyDown={handleKeyDown}
										defaultValue={response}
										/>
									<img className='save'
										onClick={() => handleSave(position)} 
										src={icon_save} />
									</>

									}
								</td>
							</>
					)
				}
				return list
		}

	return <div className='section-tab'>
				{ popup ? <ExportPopup data={dataTab} sites={sitesUrls} 
						close={() => setPopup(0)}/> : '' }

				<div className='export-button' onClick={() => setPopup(1)}>
					<img src={icon_export} alt='icon exportation data to csv'/>
					<div>Export Ad Report</div>
				</div>

				{ sites.map( (site, index) => 
							seeMore[data[site].url] ?

							<Table key={'T-'+index} site={site} data={data} openTab={0}
							handleClickCopied={handleClick}
							handleClickSeeMore={handleClickSeeMore}
							limitHeadline={data[site].headline.length} 
							limitDescription={data[site].description.length} 
							generateRow={generateRow}
							seeMoreStatus={seeMore[data[site].url]}
							/> 

						:	

						<Table key={'T-'+index} site={site} data={data} openTab={1}
							handleClickCopied={handleClick}
							handleClickSeeMore={handleClickSeeMore}
							limitHeadline={3} limitDescription={3} 
							generateRow={generateRow}
						/>
		)}
				<div className='button-back' onClick={backward} >Go back</div>
				</div>
}

function Table({ site, data, openTab,
		handleClickCopied, handleClickSeeMore,
		limitHeadline, limitDescription, generateRow,
		seeMoreStatus
}){
		const headline = generateRow(site, 'headline', limitHeadline)
		const description = generateRow(site, 'description', limitDescription)

		return <table key={site}>
						<thead>
							<tr>
								<td colSpan='2' className='thead'>
									<div className='section-url-thead'>
										<strong>URL Landing Page</strong>
										<div className='urlName' onClick={handleClickCopied}>
											{data[site].url}
										</div>
										<div className='seeMore'
											onClick={() => handleClickSeeMore(data[site].url, openTab)}>
											{seeMoreStatus ? 'See less' : 'See more'}
										</div>
									</div>
							</td>
							</tr>
						</thead>
						<tbody>
								{headline.map( (el, i) => <tr key={i+'-h'}>{el}</tr>)}
								{description.map( (el,i) => <tr key={i+'-d'}>{el}</tr>)}
						</tbody>
						</table>
}

export default TableData
