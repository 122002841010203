import React from 'react'

function Notify({list, setList}){
	const removeItem = (id) => {
		const indexItemToRemove = list.findIndex(el => el.id == id)
		list.splice(indexItemToRemove, 1)
		setList([...list])
	}
	return <div className='notify-list'>
						<ul>
							{list.map( notif => <li key={notif.id}>
									<NotifyItem 
										type={notif.type} 
										content={notif.content} 
										remove={() => removeItem(notif.id)}
									/>
								</li>)}
						</ul>
				</div>
}

function NotifyItem({type, content, remove}){
	return <div className={'notify-item ' + type} onClick={remove}>
					<div className='message'>{content}</div>
					<div className='close'>X</div>
				</div>
}

export default Notify
